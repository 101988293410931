<template>
    <div>
        <nav-admin />
        <div class="container">
            <div class="row mt-4 mb-2">
                <div class="col-12">
                    <h2 class="h2">Customers</h2>
                </div>
            </div>
            <div>
                <customers-filter />
            </div>
            <customers-table />
        </div>
    </div>
</template>
<script>
import navAdmin from './../../components/nav-admin';
import customersTable from './Components/table';
import customersFilter from './Components/filter';
export default {
    components: {
        navAdmin,
        customersTable,
        customersFilter
    }
}
</script>