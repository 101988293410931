<template>
    <div class="input-group">
        <select class="custom-select float-left" v-model="value" @change="handleSelect">
            <option v-for="(country, index) in countries" :value="country.key" :key="index">{{ country.title }}</option>
        </select>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    props: [ 'rule'],
    data() {
        this.value = this.rule.value ? this.rule.value : 'no-country';

        return {
            countries: [
                {
                    key: 'no-country',
                    title: 'No country'
                }
            ]
        }
    },
    methods: {
        ...mapMutations(['setRuleValue']),
        handleSelect(event) {
            this.setRuleValue({
                index: this.$store.getters.getCreterias.indexOf(this.rule),
                value: event.target.value
            });
        }
    },
    mounted() {
        this.$http({ url: 'customers/countries', method: 'GET'})
            .then(response => {
                for(let i = 0; i <= response.data.countries.length; i++) {
                    if(response.data.countries[i] && response.data.countries[i].country) {
                        this.countries.push({
                            key: response.data.countries[i].country,
                            title: response.data.countries[i].country
                        });
                    }
                }
            });

        this.setRuleValue({
            index: this.$store.getters.getCreterias.indexOf(this.rule),
            value: this.rule.value ? this.rule.value : 'no-country'
        });
    }
}
</script>
