<template>
    <div class="form-row">
        <div class="col">
            <b-form-datepicker v-model="valueA" @input="onInputA" :state="(isTouchedA && (isEmptyA && isEmptyB)) ? false : null" reset-button :max="max"></b-form-datepicker>
            <span class="invalid-feedback"  v-if="isTouchedA && (isEmptyA && isEmptyB)">Required field</span>
        </div>
        <div class="col">
            <b-form-datepicker v-model="valueB" @input="onInputB" :state="(isTouchedB && (isEmptyA && isEmptyB)) ? false : null" reset-button :max="max"></b-form-datepicker>
            <span class="invalid-feedback" v-if="isTouchedB && (isEmptyA && isEmptyB)">Required field</span>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    props: [ 'rule'],
    data() {
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        const maxDate = new Date(today);
     
        return {
            max: maxDate,
            tochedA: false,
            tochedB: false,
            valueA: null,
            valueB: null
        }
    },
    methods: {
        ...mapMutations(['setRuleValue']),
        onInputA(date) {
            this.valueA = date;
            
            this.setRuleValue({
                index: this.$store.getters.getCreterias.indexOf(this.rule),
                value: [this.valueA, this.valueB]
            });
        },
        onInputB(date) {
            this.valueB = date;
    
            this.setRuleValue({
                index: this.$store.getters.getCreterias.indexOf(this.rule),
                value: [this.valueA, this.valueB]
            });
        }
    },
    computed: {
        isEmptyA() {
            return !this.valueA;
        },
        isEmptyB() {
            return !this.valueB;
        },
        isTouchedA() {
            return this.tochedA || this.$store.getters.getToched;
        },
        isTouchedB() {
            return this.tochedB || this.$store.getters.getToched;
        }
    },
    mounted() {
        this.valueA = this.rule.value && this.rule.value[0] ? this.rule.value[0] : null;
        this.valueB = this.rule.value && this.rule.value[1] ? this.rule.value[1] : null;
        this.setRuleValue({
            index: this.$store.getters.getCreterias.indexOf(this.rule),
            value: [this.valueA, this.valueB]
        });
    }
}
</script>
