<template>
    <div>
        <div>
            <div v-for="group in getGroups" :key="group">
                <div v-if="group" class="card mt-2">
                    <filter-group :groupIndex="group" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="filter-costumer">
                <div>
                    <button @click="createGroup" class="btn btn-primary mb-3 mt-2 mr-1">Add Filter</button>
                    <button @click="onFilter" class="btn btn-success mb-3 mt-2 mr-1">Refresh</button>
                </div>
                <div>
                    <button @click="onGetCustomersForevent" class="btn btn-primary mb-3 mt-2 mr-1 createCampaign">Create Campaign</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import filterGroup from './filter-group';
import { randString } from '../../../mixins/rand-string.mixin';
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
    components: { filterGroup },
    mixins:[randString],
    data() {  
        return {
            rules: [],
            validate: false
        }
    },
    computed: mapGetters(['getGroups', 'checkIsValid', 'getEvent']),
    methods:{
        ...mapActions(['fetchCustomers', 'getEventCustomersNewList', 'createEventAction']),
        ...mapMutations(['createGroup', 'touch', 'updateCurrentPage']),
        async onFilter() {
            this.touch();
            if(this.checkIsValid) {
                this.updateCurrentPage(1);
                await this.fetchCustomers();
            }
        },
        async onGetCustomersForevent() {
            await this.getEventCustomersNewList();       
            await this.createEventAction();
            
            this.$router.push({ 
                name: 'EventEditor',
                params: { 'event_id': this.getEvent.id }
            });
        }
    }
}
</script>
<style scoped>
    .filter-costumer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 15px;
    }
    .row .createCampaign  {
        margin-right:0px !important; 
    }
</style>