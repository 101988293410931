<template>
    <div class="form-row">
        <div class="col">
            <input class="form-control" v-model="valueA" @blur="tochedA = true" :class="{ 'is-invalid': isTouchedA && ((isEmptyA && isEmptyB) || isNotNumberA) }" @input="handleInputA" type="text" placeholder="From" />
            <span class="invalid-feedback" v-if="isTouchedA && (isEmptyA && isEmptyB)">Required field</span>
            <span class="invalid-feedback" v-if="isTouchedA &&  isNotNumberA">Value can not be a string.</span>
        </div>
        <div class="col">
            <input class="form-control" v-model="valueB" @blur="tochedB = true" :class="{ 'is-invalid': isTouchedB && ((isEmptyA && isEmptyB) || isNotNumberB) }" @input="handleInputB" type="text" placeholder="To" />
            <span class="invalid-feedback" v-if="isTouchedB && (isEmptyA && isEmptyB)">Required field</span>
            <span class="invalid-feedback" v-if="isTouchedB && isNotNumberB">Value can not be a string.</span>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    props: [ 'rule'],
    computed: {
        isEmptyA() {
            return !this.valueA;
        },
        isEmptyB() {
            return !this.valueB;
        },
        isNotNumberA() {
            return this.valueA && isNaN(this.valueA);
        },
        isNotNumberB() {
            return this.valueB && isNaN(this.valueB);
        },
        isTouchedA() {
            return this.tochedA || this.$store.getters.getToched;
        },
        isTouchedB() {
            return this.tochedB || this.$store.getters.getToched;
        }
    },
    data() {
        return {
            valueA: null,
            valueB: null,
            tochedA: false,
            tochedB: false
        }
    },
    methods: {
        ...mapMutations(['setRuleValue']),
        handleInputA(event) {
            this.valueA = event.target.value;
            
            this.setRuleValue({
                index: this.$store.getters.getCreterias.indexOf(this.rule),
                value: [this.valueA, this.valueB]
            });
        },
        handleInputB(event) {
            this.valueB = event.target.value;
            
            this.setRuleValue({
                index: this.$store.getters.getCreterias.indexOf(this.rule),
                value: [this.valueA, this.valueB]
            });
        }
    },
    mounted() {
        this.valueA = this.rule.value && this.rule.value[0] ? this.rule.value[0] : null;
        this.valueB = this.rule.value && this.rule.value[1] ? this.rule.value[1] : null;
        this.setRuleValue({
            index: this.$store.getters.getCreterias.indexOf(this.rule),
            value: [this.valueA, this.valueB]
        });
    }
}
</script>
