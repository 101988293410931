<template>
    <div>
        <div class="col-12">
            <loading :isLoading="isLoading" />
            <div class="spiner-wrapper" v-if="getCustomersTotal == 0 && !isLoading">
                <b>Nothing found</b>
            </div>
            <b-table
                v-if="!isLoading && getCustomersTotal > 0"
                id="customers"
                :items="getCustomers"
                :fields="fields"
                :per-page="getCustomers.length"
                :current-page="1"
                :sort-by.sync="getSortField" 
                :sort-desc.sync="isSortDirDesc"
                @sort-changed="sortingChanged"
                :small="true"
                >
                
                <template v-slot:head(full_name)>
                    <div class="slot-Td-Inner">
                        Full name
                    </div>
                </template>
                
                <template v-slot:head(email)>
                    <div class="slot-Td-Inner">
                        Email
                    </div>
                </template>

                <template v-slot:head(country)>
                    <div class="slot-Td-Inner">
                        Country
                    </div>
                </template>

                <template v-slot:head(spent_money_total)>
                    <div class="slot-Td-Inner">
                        Paid amount
                    </div>
                </template>

                <template v-slot:head(completed_orders_total)>
                    <div class="slot-Td-Inner">
                        Completed
                    </div>
                </template>

                <template v-slot:head(in_progress_orders_total)>
                    <div class="slot-Td-Inner">
                        In progress
                    </div>
                </template>

                <template v-slot:head(last_order_date)>
                    <div class="slot-Td-Inner">
                        Last order date
                    </div>
                </template>
            
                <template v-slot:cell(full_name)="data">
                    <router-link :to="{ name: 'Customer', params: { 'id': data.item.id }}">{{ data.item.full_name }}</router-link>
                </template>

                <template v-slot:cell(email)="data">
                    {{ data.item.email }}
                </template>

                <template v-slot:cell(country)="data">
                    {{ data.item.country }}
                </template>

                <template v-slot:cell(spent_money_total)="data">
                    {{ data.item.spent_money_total ? '$' + data.item.spent_money_total : '' }}
                </template>
                
                <template v-slot:cell(completed_orders_total)="data">
                    {{ data.item.completed_orders_total }}
                </template>

                <template v-slot:cell(in_progress_orders_total)="data">
                    {{ data.item.in_progress_orders_total }}
                </template>

                <template v-slot:cell(last_order_date)="data">
                    {{ data.item.last_order_date ? convertBrouserTZDateOnly(data.item.last_order_date) : '' }}
                </template>
            </b-table>
        </div> 
        <div  class="col-12">
            <b-pagination
                align="center"
                v-model="getCurrentPage"
                @change="pageChange"
                :total-rows="getCustomersTotal"
                :per-page="getPerPage"
            ></b-pagination>
            <nav class="per-page-block" aria-label="...">
                <ul class="pagination pagination-md">
                    <li class="page-item" :class="{'disabled': getPerPage == 10}">
                        <span class="page-link" @click="perPageChange(10)">10</span>
                    </li>
                    <li class="page-item" :class="{'disabled': getPerPage == 30}">
                        <span class="page-link" @click="perPageChange(30)">30</span>
                    </li>
                    <li class="page-item" :class="{'disabled': getPerPage == 100}">
                        <span class="page-link" @click="perPageChange(100)">100</span>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>
<script>
import loading from './../../../components/admin/spiner';
import { timeZone } from '../../../mixins/time-zone.mixin';
import { mapMutations, mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            fields: [
                { key: 'full_name', label: "Full name", sortable: true },
                { key: 'email', label: "Email", sortable: true },
                { key: 'country', label: "Country", sortable: true },
                { key: 'spent_money_total', label: "Paid Amount", sortable: true },
                { key: 'completed_orders_total', label: "Completed", sortable: true },
                { key: 'in_progress_orders_total', label: "In progress", sortable: true },
                { key: 'last_order_date', label: `Last order date`, sortable: true }
            ],
        }
    },
    components: { loading },
    mixins:[timeZone],
    computed: {
        ...mapGetters([
            'getPerPage', 
            'getCurrentPage',
            'getCustomers',
            'getCustomersTotal',
            'getSortField',
            'getSortDirection',
            'isLoading'
        ]),
        isSortDirDesc() {
            return this.getSortDirection == 'desc';
        }
    },
    methods: {
        ...mapActions(['fetchCustomers']),
        ...mapMutations([
                'updateCurrentPage',
                'updateItemsPerPage',
                'updateSortField',
                'updateSortDir'
            ]),
        pageChange(page) {
            this.updateCurrentPage(page);
            this.fetchCustomers();
        },
        perPageChange(val) {
            this.updateItemsPerPage(val);
            this.fetchCustomers();
        },
        sortingChanged(ctx) {
            this.updateSortField(ctx.sortBy);
            this.updateSortDir(ctx.sortDesc ? 'desc' : 'asc');
            this.fetchCustomers();
        }
    },
    async mounted() {
        await this.fetchCustomers();
    }
}
</script>
<style lang="scss" scoped>
    .col-12 {
        font-size: 13px;
        padding: 0px!important;

    }
    .per-page-block {
        position: absolute;
        top: 0;

        span {
            cursor: pointer;
        }
    }

</style>