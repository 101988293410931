<template>
    <div>
        <input class="form-control" v-model="rule.value" @blur="touched = true" :class="{ 'is-invalid': !isValid && isTouched }" @input="handleInput" type="text" />
        <span class="invalid-feedback" v-if="!isValid && isTouched">Required field</span>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
    data() {
        return {
            touched: false
        }
    },
    props: [ 'rule'],
    computed: {
        isValid() {
            return !!this.rule.value;
        },
        isTouched() {
            return this.touched || this.$store.getters.getToched;
        }
    },
    methods: {
        ...mapMutations(['setRuleValue']),
        handleInput(event) {
            this.setRuleValue({
                index: this.$store.getters.getCreterias.indexOf(this.rule),
                value: event.target.value
            });
        }
    }
}
</script>
