<template>
    <div class="card-body">
        <div class="row" v-for="rule in getCreterias" :key="rule.id">
            <div v-if="groupIndex == rule.group" class="col-11">
                <rule-row :rule="rule" />
            </div>
            <div v-if="groupIndex == rule.group" class="col-1">
                <div @click="deleteCreteria(rule)" class="btn btn-danger">Delete</div>
            </div>
        </div>
        <button @click="addCriteria(groupIndex)" class="btn btn-primary">Add Criteria</button>
    </div>
</template>
<script>
import ruleRow from './filter-item';
import { mapGetters, mapMutations } from 'vuex';

export default {
    components: { ruleRow },
    props: ['groupIndex'],
    computed: mapGetters(['getGroups', 'getCreterias']),
    methods: {
        ...mapMutations(['addCriteria', 'deleteCreteria'])
    }

}
</script>