<template>
    <div class="input-group">
        <select class="custom-select float-left" v-model="rule.value" @change="handleSelect">
            <option v-for="(country, index) in countries" :value="country.key" :key="index">{{ country.title }}</option>
        </select>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    props: ['rule'],
    data() {
        return {
            countries: [
                {
                    key: 'no-orders',
                    title: 'No orders'
                },
                {
                    key: 'panding',
                    title: 'Panding'
                },
                {
                    key: 'pre-cancel',
                    title: 'Pre-cancel'
                },
                {
                    key: 'in-process',
                    title: 'In progress'
                },
                {
                    key: 'paused',
                    title: 'Paused'
                },
                {
                    key: 'canceled',
                    title: 'Canceled'
                },
                {
                    key: 'completed',
                    title: 'Completed'
                }
            ]
        }
    },
    methods: {
        ...mapMutations(['setRuleValue']),
        handleSelect(event) {
            this.setRuleValue({
                index: this.$store.getters.getCreterias.indexOf(this.rule),
                value: event.target.value
            });
        }
    },
    mounted() {
        
        this.setRuleValue({
            index: this.$store.getters.getCreterias.indexOf(this.rule),
            value: this.rule.value ? this.rule.value : 'no-orders'
        });
    }
}
</script>
