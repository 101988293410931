<template>
    <div class="row mb-3">
        <div class="col-3">
            <select class="custom-select" v-model="rule.field" :disabled="rule.fieldsDisabled" @change="handleSelect">
                <option v-for="(item, index) in fields" :value="item.key" :key="index">{{ item.title }}</option>
            </select>
        </div>
        <div class="col-8">
            <string-field v-if="rule.field == 'email'" :rule="rule" />
            <string-field v-if="rule.field == 'full_name'" :rule="rule" />
            <country-select v-if="rule.field == 'country'" :rule="rule" />
            <number-comparing v-if="rule.field == 'total_spent_money'" :rule="rule" />
            <date-filter-input v-if="rule.field == 'last_order_date'" :rule="rule" />
            <order-types-field v-if="rule.field == 'last_order_type'" :rule="rule" />
            <date-filter-input v-if="rule.field == 'last_marketing_event_date'" :rule="rule" />
        </div>
    </div>
</template>
<script>
import countrySelect from './FilterTypes/filter-country-field';
import numberComparing from './FilterTypes/filter-number-field';
import dateFilterInput from './FilterTypes/filter-date-field';
import stringField from './FilterTypes/filter-string-field';
import orderTypesField from './FilterTypes/filter-order-types-field';
import { mapMutations } from 'vuex';

export default {
    components: { countrySelect, numberComparing, dateFilterInput, stringField, orderTypesField },
    props: ['rule'],
    computed: {
        fields() {
            return this.rule.fields.filter(function({hidden}) {
                return hidden == false;
            });
        }
    },
    watch: {
        value(val) {
            this.rule.value = val;
            this.emitValue();
        }
    },
    methods: {
        ...mapMutations(['changeCreteriaType']),
        handleSelect(event) {
            this.changeCreteriaType({
                index: this.$store.getters.getCreterias.indexOf(this.rule), 
                value: event.target.value
            });
        }
    }
}

</script>